.analysis-row {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  width: 100%;
  border: 1px solid #d3d3d3;
}

.analysis-column {
  display: flex;
  flex-direction: column;
  width: 100%; /* Make each column take the full width of the row */
  max-width: 100%;
  margin: 10px 0; /* Add vertical spacing between rows */
  align-items: center; /* Center-align content within each row */
  text-align: center; /* Center-align text within each column */
}
