input, textarea {
  width:100%;
  padding:10px;
  font-family: sans-serif;
  border: 1px solid $grey;
  &.input {
    &--error {
      border-color: red;
    }
  }
}

label {
  padding: 10px 0;
  display: block;
}

.form {
  background: white;
  margin-top:10px;
  box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
  padding: 2rem;
  box-shadow: $grad;
  & + .form {
    margin-top: 4rem;
  }
  // I know this isn't bem but meh
  h2 {
    margin: 0;
    font-size: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid $grey;
  }
}
