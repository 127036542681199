
//this controls the css for the folder tabs on the emaildetailv2/:ID page
$color_1: #555;
$color_2: #bbb;
$color_3: #888;
$font-family_1: fontawesome;

@import "https://kit.fontawesome.com/0d64d392b4.js";
main {
	margin: 0 auto;
	//border: 2.5px solid #FFA500;
	width:100%;
	display: flex;//
    flex-direction: column; // Ensures the main elements stack vertically//

}
// Styling for the tabs container
.tabs-container {
    display: flex;
    justify-content: space-around;
    background-color: #f0f0f0;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    margin-bottom: 20px; // Space between tabs and content
}
.tabletab {
	//background-color: yellow;
	//border: 2px solid red;
  	border-radius: 5px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 20px;
}
section {
	display:none;
	//display: flex;
	//flex-wrap:wrap;
	padding: 20px 0 0;
	border-top: 1px solid #ddd;
}
input[type="radio"][name="tabs"] {
	display: none;
	&:checked {
		+ {
			label {
				color: $color_1;
				border: 1px solid #ddd;
				border-top: 2px solid orange;
				border-bottom: 1px solid #fff;
			}
		}
	}
}
label {
	display: inline-flex;
	align-items: center;//
    justify-content: left;//
	margin: 0 0 -1px;
	padding: 15px 25px;
	font-weight: 600;
	text-align: center;
	color: $color_2;
	border: 1px solid transparent;
	&:before {
		font-family: $font-family_1;
		font-weight: normal;
		margin-right: 10px;
	}
	&:hover {
		color: $color_3;
		cursor: pointer;
	}
}
label[for*='1'] {
	&:before {
		content: '\f073';
	}
}
label[for*='2'] {
	&:before {
		content: '\f03a';
	}
}
label[for*='3'] {
	&:before {
		content: '\f201';
	}
}
label[for*='4'] {
	&:before {
		content: '\f14d';
	}
}
#tab1 {
	&:checked {
		~ {
			#content1 {
				display: block;
			}
		}
	}
}
#tab2 {
	&:checked {
		~ {
			#content2 {
				display: block;
			}
		}
	}
}
#tab3 {
	&:checked {
		~ {
			#content3 {
				display: block;
			}
		}
	}
}
#tab4 {
	&:checked {
		~ {
			#content4 {
				display: block;
			}
		}
	}
}
@media screen and (max-width: 650px) {
	label {
		//font-size: 0;
		&:before {
			margin: 0;
			font-size: 18px;
		}
	}
}
@media screen and (max-width: 400px) {
	label {
		padding: 15px;
	}
}

