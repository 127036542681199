
.fa-solid {
	padding-right: 10px;
}

.sidebar-new {
	display:flex;
	flex-wrap:wrap;
	align-content: flex-start;
	width: 0;
    overflow: hidden; /* To prevent content overflow when sidebar is hidden */
	transition: width 0.5s ease, opacity 0.5s ease;
    visibility: hidden; /* Start as hidden */
	opacity: 0; /* Start as fully transparent */
}

.sidebar-new.active {
	display:flex;
	flex-wrap:wrap;
	transition: width 0.5s ease, opacity 0.5s ease;
	width: 200px; /* Adjust to your desired width */
	min-width: 40px;
	opacity: 1; /* Transition to fully opaque */
    visibility: visible; /* Make visible when active */
    margin-top: 20px;
    background: white;
    margin-right: 1rem;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    padding: 8px;
    font-family: "panama";
}

/* Media Query for medium screens - Adjust behavior at 1200px */
@media screen and (max-width: 1200px) {
    .sidebar-new {
        width: 0;
        visibility: hidden;
        left: -200px;
    }
    .sidebar-new.active {
        width: 200px;
        visibility: visible;
        left: 0;
		opacity: 1; /* Transition to fully opaque */
    	transition: width 0.5s ease, opacity 0.5s ease;
    	visibility: visible; /* Make visible when active */
    }
}

/* Media Query for smaller screens - Hide sidebar below 800px */
@media screen and (max-width: 800px) {
    .sidebar-new {
        width: 0;
        visibility: hidden;
        left: -200px;
		opacity: 0;
        transition: width 0.5s ease, opacity 0.5s ease;
    }
    .sidebar-new.active {
        width: 200px;
        visibility: visible;
        left: 0;
		opacity: 1; /* Transition to fully opaque */
    	transition: width 0.5s ease, opacity 0.5s ease;
    	visibility: visible; /* Make visible when active */
    }

}

@media screen and (max-width: 800px) {
	.sidebar-new span {
        display: none;
    }
	.sidebar-new.active {
        width: 40px;
	}
}