.emails {
    display:flex;
    flex-wrap:wrap;
    //border: 5px solid goldenrod;
    justify-content: center;
  }
  
  .email {
    display:flex;
    flex-wrap:wrap;
    width: 100%;
    margin-top: 1rem;
    //border: 5px solid goldenrod;
    box-shadow: $shad;
    &--wide {
      width: 100%;
    }
    &__details {
      padding: 2rem;
      display:flex;
      flex-wrap:wrap;
      flex-direction: column;
      background: white;
      width: 100%;
      // clip-path: polygon(0px 2vw, 100% 0, 100% calc(100% - 2vw), 0% 100%);
      position: relative;
      padding: 2.5rem;
      //border: 2.5px solid #FFA500;
      box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
      p {
        line-height: 1.2;
        //margin: 1 ;
      }
    }  
  }
  
  .email__actions {
    position: relative;
    z-index: 2;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    box-shadow: 0 1px 0 rgba(0,0,0,0.2), 0 1px 0 rgba(255,255,255,0.2);
    margin-bottom: 50px;
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-around;
  }
  
  .email__action {
    font-size: 10px;
    svg {
      width: 25px;
      fill: white
    }
    &--edit {
      a {
        border-bottom: 0;
      }
    }
  }

  .email__hero {
    display:flex;
    flex-wrap:wrap;
    display: block;
    padding: 20px 20px 0px 20px;
    position: relative;
    height: 100%;
    width: 100%;
    box-shadow: $shad;
    background-color: white;
    margin-top: 10px;
    //border: 2.5px solid #FFA500;
  }

  .email__line {
    border-top: 1px solid black;
  }

    
   /* Create two equal columns that floats next to each other */
  .email__column {
    display:flex;
    flex-wrap:wrap;
    float: left;
    width: 49.5%;
    max-width: 100%; /* Ensure it doesn’t exceed the layout */
    overflow: hidden; /* Hide any overflowing content */
    //margin-top: 10px;
    //margin-right: 2px;
    //margin-left: 2px;
    //padding: 10px;
    //background: white;
    // clip-path: polygon(0px 2vw, 100% 0, 100% calc(100% - 2vw), 0% 100%);
    position: relative;
    //padding: 1rem;// keep this so columns don't overlap
    //box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
  }

  /* Clear floats after the columns */
  .row:after {
    content: "";
    display: table;
    clear: both;
    //padding: 2rem;
    //background: white;
    // clip-path: polygon(0px 2vw, 100% 0, 100% calc(100% - 2vw), 0% 100%);
    position: relative;
    //padding: 3rem;
    //box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
  }
  @media screen and (max-width: 850px) {
    .email__column {
      width: 100%;
    }
  }

  //email chart data from https://chartscss.org/charts/column/
  #email_daily_counts.column {
    height: 200px;
    max-width: 98%;
    margin-top: 175px;
    background: white;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    padding: 15px;
  }

  .emailData {
    display: flex;
    flex-wrap:wrap;
    flex: 3;
    //max-width:1600px;
    //border: 5px solid goldenrod;
    margin: 0 auto;
    //background: white;
    // clip-path: polygon(0px 2vw, 100% 0, 100% calc(100% - 2vw), 0% 100%);
    position: relative;
    //padding: 2rem;
    margin-top: 1rem;
    //new items
    align-items: center;
    justify-content: center;
    word-wrap: break-word;
    //overflow: auto;
    //max-width: 90%;
    //padding: 20px;
    //end items
    //box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    @media all and (max-width: 900px) {
      padding: 2rem;
    }
  }


  .emailData select {
    width: fit-content;
    min-width: 150px;
    max-width: 100%;
    padding: 10px;
    //font-size: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 10px 0;
}

button.analysis {
  line-height: 1.65;
}
//.emailData p {
//  word-wrap: break-word;
//  max-width: 100%;
//  text-align: center;
//}

@media (max-width: 850px) {
  .emailData {
      flex-direction: column;
      align-items: flex-start;
  }

  .emailData select {
      width: 100%;
      font-size: 0.9rem;
      padding: 8px;
  }

  button.analysis {
      width: 100%;
  }
}

  .left-column {
    float: left;
    //margin-right: 20px; /* Adjust the value as needed */
  }

  .right-column {
    float: right;
  }