.bookmark-btn i {
    //width: 100%;  /* Adjust as needed */
    height: 1em; /* Adjust as needed */
    min-width: 25px;
    
    
}

.bookmark-btn {
    width: 100%;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    background-color: #ffffff;
    border-radius: 0px;
    border: 2.5px solid #FFA500;
    margin: auto;
    text-align: center;
    text-decoration: none;
    display: block; /* Changed from inline-block to block */
    font-size: 1.5rem;
    padding: 2px 18px 2px 5px;
    position: absolute; /* Positioning it absolutely within the relative parent */
    top: 10px; /* Center vertically */
    left: 50%; /* Center horizontally */
    transform: translate(-50%, -50%); /* Adjust the position to be truly centered */
}