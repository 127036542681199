/* Blog Cards Container */
.blog-cards {
    display: flex;
    flex-wrap: wrap;
    padding: 1.25em; /* Adds padding to the container */
    margin: 1.25em; /* Negative margin to counteract the padding on the cards */
    overflow: hidden; /* Helps contain floated/extended elements */
  }
  
  /* Individual Blog Card */
  .blog-card {
    background: white;
    margin: 1em; /* Uniform margin */
    width: calc(33.333% - 2em); /* 3 columns on desktop */
    height: 300px;
    box-shadow: var(--shadow, 0 1px 3px rgba(0,0,0,0.2)); /* Customizable shadow variable */
    position: relative; /* For positioning elements inside the card */
  }
  
  /* Blog Card Details */
  .blog-card__details {
    padding: 2rem;
    p {
      line-height: 1.2;
      margin-bottom: 0;
    }
  }
  
  /* Responsive Adjustments */
  @media all and (max-width: 550px) {
    .blog-cards {
      padding: 0 10px; /* Less padding on smaller screens */
      margin: -1.25em 0 -1.25em 10px; /* Smaller margins on smaller screens */
    }
  }
  
  @media all and (max-width: 850px) {
    .blog-card {
      width: calc(50% - 2em); /* 2 columns in mid-sized devices */
    }
  }
  
  @media all and (max-width: 550px) {
    .blog-card {
      width:  calc(100% - 2em); /* 1 column on small devices */
    }
  }
  
  /* Blog Card Actions (if needed) */
  .blog-card__actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    border-top: 1px solid rgba(0,0,0,0.2);
    background: rgba(255,255,255,0.9); /* Slightly transparent white */
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    z-index: 2; /* Ensures it's above other content */
  }
  
  /* Blog Card Action Icons or Links */
  .blog-card__action {
    font-size: 14px; /* Slightly larger font for readability */
    color: #333; /* Darker text for better visibility */
    svg {
      fill: currentColor; /* Inherits the color from parent */
    }
  }
  
  /* Blog Card Hero Image Styling */
  .blog-card__hero {
    height: 200px;
    position: relative;
    overflow: hidden; /* Ensures images don't escape the container */
  }
  
  /* Before pseudo-element for additional styling */
  .blog-card__hero:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(0,0,0,0.3) 100%);
    z-index: 1; /* Above the image, under the text */
  }
  
  /* Hero image */
  .blog-card__hero img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  
  /* Adjustments for medium screens, e.g., tablets */
@media (max-width: 1400px) {
    .title {
      font-size: 1.85em; /* Slightly smaller */
    }
  }

@media (max-width: 1000px) {
  .title {
    font-size: 1.85em; /* Slightly smaller font size on medium screens */
  }
}

/* Adjustments for small screens, e.g., mobile phones */
@media (max-width: 750px) {
  .title {
    font-size: 1.5em; /* Even smaller font size on small screens */
  }
}
