

.nav {
  display: flex;
  list-style: none;
  //border: 5px solid goldenrod; //this is just to help figure out the flexbox and items: take out when done
  margin: 0;
  padding: 0;
  font-family: Panama;
  justify-content: space-between;
  //justify-content: flex-end;
  background: $dark-blue;
  &__section {
    display: flex;
    &--search {
      flex: 1 1 auto;
    }
  }
  &__item {
    display: flex;
    flex-direction: row; /* Stack items vertically */
    align-items: center;
    justify-content: center;
  }
  &__link {
    background: $dark-blue;
    color: white;
    border-right: 1px solid rgba(255,255,255,0.1);
    text-transform: uppercase;
    padding: 1.2rem 2rem 1rem 2rem;
    //display: block;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: transform 0.2s;
    border-bottom: 5px solid transparent;
    gap: 8px;
    svg {
      width: 40px;
      transition: all 0.2s;
      fill: white;
      margin-bottom: 1rem;
    }
    &:hover, &--active {
      border-bottom-color: rgba(0,0,0,0.2);
      border-right-color: rgba(0,0,0,0.05);
      svg {
        transform: scale(1.2)
      }
      //background: linear-gradient(90deg, #48ded4 0%, #a026bf 20%, #e82c75 60%, $yellow 85%, #48ded4 95%) fixed;
      background: #48ded4;
      // background: none;
    }
    &--logo {
      &:hover {
        svg {
          transform: none;
        }
      }
      svg {
        width: 200px;
        margin: 0;
      }
    }
  }
}

/* Ensure UL behaves like a flex container */
.nav__section ul {
  list-style: none; /* Removes default bullet points */
  padding: 0;
  margin: 0;
  display: flex; /* Keeps list items in a horizontal row */
  align-items: center; /* Aligns vertically */
}

/* Ensure LI behaves properly inside the navbar */
.nav__section ul li {
  margin-right: 20px; /* Space between items */
}

/* Ensure last item does not have unnecessary spacing */
.nav__section ul li:last-child {
  margin-right: 0;
}

.hamburger-menu {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 30px; /* Adjust as needed */
  width: 35px; /* Adjust as needed */
}

.hamburger-menu .bar {
  height: 3px;
  width: 100%;
  background-color: #333; /* Adjust color as needed */
  border-radius: 2px;
}
.hamburger-menu {
  display: block;
  margin-left: 20px;
  margin-top: 10px
}

@media screen and (max-width: 768px) { /* Adjust as per your responsive breakpoint */
  .hamburger-menu {
      display: block;
  }

  /* You might also want to adjust the sidebar for small screens */
  .sidebar {
      width: 0; /* Hide by default on small screens */
  }
}


@media all and (max-width: 1200px) {
  .nav {
    &__link {
      padding-left: 1rem;
      padding-right: 1rem;
      svg {
        width: 30px;
      }
      &--logo {
        svg {
          width: 100px;
        }
      }
    }
  }
}

@media all and (max-width: 900px) {
  .nav {
    flex-wrap:wrap;
    &__section {
      order: 1;
      &--search {
        border-top: 1px solid rgba(255,255,255,0.1);
        order: 2;
        width: 100%;
      }
    }
  }
}

.fa-xl {
  padding-left: 20px;
}

@media screen and (max-width: 750px) {
  .nav__item.mid {
      display: none;
 }
}

/*
@media all and (max-width: 650px) {
  .nav {
    &__section {
      width: 100%;
      &--user {
        //order: 1;
        border-bottom: 1px solid rgba(255,255,255,0.1);
        .avatar {
          min-width: 20px;
          height: 20px;
        }
        svg {
          display: none;
        }
      }
    }
    &__item {
      width: 33.333333%;
    }
    &__link {
      width: 100%;
      svg {
        // width: 5px;
        max-width: 100%;
      }
      &--logo {
        svg {
          width:100%;
        }
      }
  }
  .heart-count:before {
    content: '♥️';
    margin-right: 1rem;
  }
}}*/
