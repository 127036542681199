.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //border: 2.5px solid #FFA500;
  }

.dashboard-parent {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    //justify-content: space-around;
    justify-content: center;
    height: 150px;
    width: 20%;
    border: 2.5px solid #FFA500;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    margin: 5px;
    }
  
.dashboard-number {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    display: block;
    position: absolute;
}

.dashboard-item {
    display: flex;
    flex-wrap: wrap;
    margin-top: 100px;
    margin-bottom: auto;
    display: block;
    position: absolute;
    flex: 1;
}

/* Decrease font size as the screen width decreases */

@media screen and (max-width: 1650px) {
    .dashboard-item {
      font-size: 0.95rem; /* Slightly smaller font size for screens <= 1200px */
    }
    .dashboard-number h1 {
        font-size: 3.5rem; /* Slightly smaller font size for screens <= 1200px */
      }
  }

  @media screen and (max-width: 1200px) {
    .dashboard-item {
      font-size: 0.8rem; /* Smaller font size for screens <= 1000px */
    }
    .dashboard-number h1{
        font-size: 3rem; /* Slightly smaller font size for screens <= 1200px */
      }
    .dashboard-parent-senders h3 {
        font-size: 2rem; /* Slightly smaller font size for screens <= 1200px */
    }
    .dashboard-parent-senders p {
        font-size: 1rem; /* Slightly smaller font size for screens <= 1200px */
    }
  }
  
  @media screen and (max-width: 1000px) {
    .dashboard-item {
      font-size: 0.8rem; /* Smaller font size for screens <= 1000px */
    }
    .dashboard-number h1{
        font-size: 2.7rem; /* Slightly smaller font size for screens <= 1200px */
      }
    .dashboard-parent-senders h3 {
        font-size: 1rem; /* Smaller font size for screens <= 1000px */
    }
    .dashboard-parent-senders p {
        font-size: .85rem; /* Slightly smaller font size for screens <= 1200px */
    }
  }
  
  @media screen and (max-width: 750px) {
    .dashboard-item {
      font-size: 0.8rem; /* Even smaller font size for screens <= 750px */
    }
    .dashboard-number h1{
        font-size: 4rem; /* Slightly smaller font size for screens <= 1200px */
      }
   
  }
  
  @media screen and (max-width: 500px) {
    .dashboard-item {
      font-size: 0.65rem; /* Smallest font size for screens <= 500px */
    }
    .dashboard-number h1{
        font-size: 3rem; /* Slightly smaller font size for screens <= 1200px */
      }

  }


.dashboard-parent-subjectline {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    height: 150px;
    width: 100%;
    border: 2.5px solid #FFA500;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    margin: 5px;
    }

.dashboard-parent-senders {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: left;
    justify-content: space-around;
    height: auto;
    width: 100%;
    border: 2.5px solid #FFA500;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    margin: 5px;
    }
 
/*
.dashboard-row {
    display: flex;
    
    width: 100%; 
}*/

.dashboard-sender, .dashboard-item-sender {
    //-align-items: center; 
}

.dashboard-sender {
    //-text-align: left;
    //-padding: 0px 10px;
    text-align: center;
}