
/* grid template original 
.grid {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    background-color: #ffffff;
    padding: 20px;
  }
  
.item {
    width: 100%;
  }
  */

/* this is the pinterest layout */
//OG
 // .grid { 
 //   column-count: 7;
 //   column-gap: 20px;
 //   background-color: #ffffff;
 //   padding: 20px;
 //   //border: 2.5px solid #FFA500;
 // }
  //new
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Adjust the minimum size as needed */
  //grid-template-columns: repeat(5, 1fr); /* Creates 7 equal-width columns */
  gap: 15px; /* Gap between grid items */
  //background-color: #ffffff;
  //padding: 20px;
  width: 100%; /* Ensure the grid container takes the full width */
  box-sizing: border-box; /* Include padding in the width calculation */
  //border: 2.5px solid #FFA500;
}

  .item {
    margin: 0;
    display: grid;
    grid-template-rows: 1fr auto;
    //margin-bottom: 15px;
    break-inside: avoid;
    position:relative;
    //border-radius: 4px;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    //border: 2.5px solid #FFA500;
    padding: 10px;
    background-color: #ffffff;
  }

  .item img {
    grid-row: 1 / -1;
    grid-column: 1;
    height: 400px;
    width: 100%;
    object-fit: cover; /* Ensures the image covers the area without stretching */
    object-position: top; /* Aligns the top of the image with the top of the container */
    //border-radius: 4px; /* Optional: adds a border radius to the images */
  }

  //.item > img {
  //  grid-row: 1 / -1;
  //  grid-column: 1;
  //  height: 400px;
  //  width: 100%;
  //  object-fit: cover; /* Ensures the image covers the area without stretching */
  //  object-position: top; /* Aligns the top of the image with the top of the container */
  //  border-radius: 4px; /* Optional: adds a border radius to the images */
  //}
 

  .overlay {
    position: absolute; 
    border-radius: 5px;
    border-style: solid;
    border-color: #48DED4;
    bottom: -15px; 
    left: 1px;
    background: rgb(0, 0, 0);
    background: rgba(0, 9, 14, 0.9); /* Black see-through */
    color: #f1f1f1; 
    width: 100%;
    transition: .5s ease;
    opacity:0;
    color: white;
    font-size: 20px;
    padding: 0px;
    text-align: center;
  }
  
  .item:hover .overlay {
    opacity: 1;
  }

  
/* these control the column in the visual inbox for different screen sizes */ 
/* Media query for screen width 1400px to 1700px */
@media screen and (max-width: 1700px) {
  .grid {
    column-count: 5;
  }
}

/* Media query for screen width 1100px to 1400px */
@media screen and (max-width: 1400px) {
  .grid {
    column-count: 4;
  }
}

/* Media query for screen width 900px to 1100px */
@media screen and (max-width: 1100px) {
  .grid {
    column-count: 3;
  }
}

/* Media query for screen width 600px to 900px */
@media screen and (max-width: 900px) {
  .grid {
    column-count: 2;
  }
}

/* Optionally, if you want a single column layout for very narrow screens */
@media screen and (max-width: 600px) {
  .grid {
    column-count: 1;
  }
}