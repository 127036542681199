.tab, .tabcontent, .tablinks {
	box-sizing: border-box;
}

.tab {
	float: left;
	border: 1px solid #ccc;
	background-color: #f1f1f1;
	width: 30%;
	height: 400px;
	display: block;
	button {
		display: block;
		background-color: inherit;
		color: black;
		padding: 22px 16px;
		width: 100%;
		border: none;
		outline: none;
		text-align: left;
		cursor: pointer;
		transition: 0.3s;
		font-size: 17px;
		&:hover {
			background-color: #0ec99e;
		}
	}
	button.active {
		background-color: #0ec99e;
	}
}
.tabcontent {
	float: left;
	padding: 0px 12px;
	border: 1px solid #ccc;
	width: 70%;
	border-left: none;
	height: 400px;
	background-color:  #fff;
}
