.datePicker {
	margin: auto;
	width: 75%;
	vertical-align: middle;
}

label {
	display: block;
	margin: .4rem 0;
}

.start {
    float: left;
}

.end {
    float: right;
}
