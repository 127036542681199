.stores {
  display:flex;
  flex-wrap:wrap;
  margin: -1.25em 0 0 -1.25em;
}

.store {
  background:white;
  margin: 1.25em 0 0 1.25em;
  max-width: 900px;
  height: 300px;
  box-shadow: $shad;
  &--wide {
    width: 100%;
  }
  &__details {
    padding: 2rem;
    p {
      line-height: 1.2;
      margin-bottom: 0;
    }
  }
  @media all and (max-width: 850px) {
    width: calc(50% - 1.25em);
  }
  @media all and (max-width: 550px) {
    width: 100%;
  }
}

.store__actions {
  position: relative;
  z-index: 2;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  box-shadow: 0 1px 0 rgba(0,0,0,0.2), 0 1px 0 rgba(255,255,255,0.2);
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: space-around;
}

.store__action {
  font-size: 10px;
  svg {
    width: 25px;
    fill: white
  }
  &--edit {
    a {
      border-bottom: 0;
    }
  }
}


.store__hero {
  position: relative;
  padding: 0 10px 0 10px;
  text-align: right;
  height: 200px;
  &:before {
    display: block;
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    /*background:
      linear-gradient(45deg, hsla(190, 95%, 43%, 1) 0%, hsla(190, 95%, 43%, 0) 70%),
      linear-gradient(135deg, hsla(219, 93%, 48%, 1) 10%, hsla(219, 93%, 48%, 0) 80%),
      linear-gradient(225deg, hsla(293, 93%, 48%, 1) 10%, hsla(293, 93%, 48%, 0) 80%),
      linear-gradient(315deg, hsla(130, 96%, 45%, 1) 100%, hsla(130, 96%, 45%, 0) 70%);*/
    opacity: 0.6;
    z-index: 1;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
  img {
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: absolute;
    left: 0;
    object-fit: cover;
    clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  }
}
