.review {
  background: white;
  border: 1px solid $grey;
  border-bottom: 0;
  border-bottom: 1px solid $grey;
  margin-bottom: 2rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  position: relative;
  padding-left: 5px;
  &:before {
    width:5px;
    left: 0;
    content: '';
    display: block;
    background: $verticalGrad;
    position: absolute;
    height: 100%;
    background-attachment: fixed;
  }
  &__header {
    border-bottom: 1px solid $grey;
    display: flex;
    & > * {
      // this is fine, don't email me about it
      border-right: 1px solid $grey;
      padding: 0.2rem;
      display: flex;
      align-items: center;
      flex: 1;
      justify-content: center;
      &:last-child {
        border-right: 0;
      }
    }
  }
  &__author {
    justify-content: flex-start;
    padding-left:  2rem;
    display: flex;
    .avatar {
      margin-right: 2rem;
    }
  }
  &__time {
    font-size: 1.2rem;
    color: lighten(black, 60%);
  }
  &__stars {
    color: $yellow;
  }
  &__body {
    padding: 2rem;
  }
  p {
    white-space:pre-wrap;
  }
}
