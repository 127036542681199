
/*.chart-container {
    display: flex;
    //flex-wrap: wrap;
    flex-direction: column;
    //position: relative;
    //margin: auto;
    width: 100%;
    border: 2.5px solid #FFA500;
    height: 400px;
  }*/
  
  //don't edit chartData
  .chartData {
    //border: 2.5px solid #FFA500;
    width: 100% !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: 25px auto;
    //margin-top: 175px; //need a media query to better show the spacing on mobile
    background: #ffffff;
    display: grid;
    //grid-gap: 20px;
    //grid-template-columns: auto auto auto auto;
    background-color: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
    padding: 10px;
    @media all and (max-width: 650px) {
      padding: 2rem;
    }
  }

  .compareChartData {
    border: 2.5px solid #FFA500;
    display: flex;
    flex-direction: column; /* Ensures items stack vertically */
    justify-content: center; /* Center items vertically */
    align-items: center; /* Center items horizontally */
    width: 55% !important;
    height: auto !important;
    max-width: 100%;
    max-height: 100%;
    margin: 25px auto;
    background: #ffffff;
    box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.15);
    padding: 10px;
    text-align: center; /* Centers text inside the container */

    @media all and (max-width: 650px) {
        padding: 2rem;
    }
}





  #mychart {
    width: 100%;
  }
  #mychart2 {
    width: 100%;
  }
  #mychart3 {
    width: 100%;
  }
  #mychart4 {
    width: 100%;
  }
  #mychart5 {
    width: 100%;
  }
  #mychart9 {
    width: 100%;
  }
  #mychart6, #mychart7, #mychart8, #mychart9, #mychart10, #mychart11, #mychart12 {
    width: 100%;
  }