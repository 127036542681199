.table {
  width: 100%;
  border-collapse: collapse;
  overflow: hidden;
  td {
    padding: 5px;
  }
  tr:nth-child(even) {
    background: white;
  }
  tr:nth-child(odd):not(:first-child) {
    background: #f7f7f7;
  }
  th {
    padding: 10px
  }
  thead tr {
    background: $dark-blue;
    color: white;
  }
}

#example tbody tr:hover {
  background-color:  #FFC40E; // #48DED4;
}