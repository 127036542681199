.grid-actions-nav{
    display:flex;
    //border: 5px solid goldenrod; 
    overflow: hidden;
    flex-wrap:wrap;
    margin: 0em;
    justify-content: center;
  }
  
  .actions {
    display:flex;
    flex-wrap:wrap;
    margin: 5em 0 0 0em;
    width: 725px;
    box-shadow: $shad;
    &--wide {
      width: 100%;
    }
    &__details {
      //border: 2.5px solid #FFA500;
      display:flex;
      flex-wrap:wrap;
      padding: 2rem;
      background: white;
      // clip-path: polygon(0px 2vw, 100% 0, 100% calc(100% - 2vw), 0% 100%);
      position: relative;
      padding: 1rem;
      margin-top: 1rem;
      box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
      p {
        line-height: 1.2;
        //margin: 1 ;
      }
    }
    @media all and (max-width: 850px) {
      width: calc(50% - 1.25em);
    }
    @media all and (max-width: 550px) {
      width: 100%;
    }  
  }

  .analysis {
    margin: 0px 10px 0px 10px
  }

  
  .form-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    //padding: 10px;
    //gap: 10px;
}
  .dropdown {
    flex: 1 1 auto;
    margin: 3.5px;
    padding: 5px;
    width: 25%;
    font-size: medium;
  }

  #button {
    flex: 0 1 auto;
    padding: 5.5px 10px;
    margin: 3.5px;
    cursor: pointer;
    font-size: medium;
    //background-color: #FFC40E;
  }


@media screen and (max-width: 1200px) {
    .form-container {
        flex-direction: column;
    }
    .dropdown, #button, input, select {
      //max-width: 50%;
      font-size:75%;
      width: 100%;
  }
}