html {
  font-size: 10px;
}

body {
  background: $purple;
  background:#efefef;
  &:before {
    content: '';
    display: block;
    background: $grad;
    height: 10px;
    width: 100%;
  }
}

.floatL{float:left;}
.floatR{float:right;}

.inner {
  //max-width:900px;
  margin: 0 auto;
  @media all and (max-width: 900px) {
    //padding: 0rem 2rem;
  }
}


/*scroll options*/
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #112733; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #48ded4;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #48ded4; 
}

.main_content {
  max-width: 900px;
  margin: auto;
}
 
.flex-grid-layout {
  display: flex;
  margin: auto;
}
.col {
  background-color: #EFEFEF;
  padding: 0rem;
}

.hidden {
  display: none;
}

.main-new {
  flex: 3;
  //background: white;
  //box-shadow: 0 0 10px 2px rgba(0,0,0,0.15);
  padding:10px;
  margin-right: 1rem;
}