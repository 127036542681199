.search {
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;
  width: 100%;
  &__results {
    background: white;
    //position: absolute;
    width: 100%;
    top: 100%;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    z-index: 5;
    left: 0;
  }
  &__input {
    font-size: 15px;
    width: 50%;
    background: none;
    color: #303030;
    outline: 0;
    border: 0;
    font-family: panama;
  }
  &__result {
    padding: 10px;
    display: block;
    font-family: panama;
    border-bottom: 1px solid #ececec;
    &--active {
      background: #f1f1f1;
    }
  }
}

.search i {
  //position: absolute;
  color: #303030;
  //left: 10px; 
  //top: 10px;
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  //border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
  /* Adjust as needed */
  /* Add other styling as needed */
}

.search i.fa.fa-search.active,
.search input.search__input.active {
  transform: translateX(200px); // Slide right by 300px 
  transition: transform 0.3s ease-in-out; // Add transition for smooth animation 
}

.search__input {
  padding-left: 35px; /* Adjust padding to ensure text doesn't overlap the icon */
  /* Add other styling as needed */
}

.filter-container {
  position: relative;
  display: inline-block;
  margin-right: 15px;
}

.filter-button {
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  //border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 18px;
}

.filter-button i {
  color: #303030;
}

.filter-dropdown {
  display: none;
  position: absolute;
  top: 5px;
  right: -200px; /* Start hidden to the ... */
  background-color: white;
  //border: 1px solid #ececec;
  //border-radius: 3px;
  //box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 5;
  transition: right 0.3s ease-in-out; /* Smooth slide-in effect */
}

.filter-dropdown.show {
  display: block;
  margin-top: 2px;
  left: 50px
}

.filter-dropdown__select {
  width: 100%;
  padding: 5px;
  border: none;
  border-radius: 3px;
  font-family: panama;
  color: #303030;
  font-size: 14px;
  appearance: none;
  background: #ffffff;
  cursor: pointer;
}
