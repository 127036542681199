/* Container and Layout */
.email__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.email__toggle {
  margin: 1rem 0;
  margin-right: 0.5rem;
  display: flex;
  gap: 10px; /* Adds spacing between all child elements */
}

.email__toggle button {
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f0f0f0;
  transition: background-color 0.3s ease;
}

.email__toggle button:hover {
  background-color: #e0e0e0;
}

/* Content Styling */
.email__content {
  width: 100%;
  max-width: 790px;
  position: relative;
}

.email__screenshot {
  width: 100%;
  display: block;
  border-radius: 4px;
}

/* Code Viewer */
.email__code * {
  background-color: #333; /* Dark background for contrast */
  box-sizing: border-box; /* Includes padding and borders in width calculation */
  color: #f8f8f2; /* Light text for readability */
  font-family: Consolas, "Courier New", monospace; /* Match code font */
  font-size: 14px;
  line-height: 1.6;
  overflow-wrap: break-word !important; /* Ensure long words wrap properly */
  overflow: hidden; /* Prevents scrollbars */
  padding: 10px;
  margin: 0; /* Align with the container */
  width: 100%; /* Full width within the container */
  max-width: 100%; /* Prevent overflow */
  white-space: pre-wrap !important; /* Allow lines to wrap */
  word-wrap: break-word !important; /* Break long words */
}

/* Ensure the editor stays within columns */
.email__column .email__code {
  width: 100%;
}

/* Line Numbers */
//pre.line-numbers {
//  position: relative;
//  padding-left: 2.8em ; /* Space for line numbers */
//  overflow: auto; /* Enable scrolling */
//  width: 100%; /* Constrain to parent width */
//  max-width: 100%; /* Prevent overflow */
//  white-space: pre-wrap; /* Allow wrapping of lines */
//}
//
//.line-numbers-rows {
//  position: absolute;
//  top: 0;
//  left: 0;
//  width: 2.8em; /* Fixed width for line numbers */
//  border-right: 1px solid #555; /* Line number separator */
//  background-color: #444; /* Background for line numbers */
//}
//
//.line-numbers-rows > span {
//  display: block;
//  text-align: right;
//  padding-right: 0.5em; /* Space between numbers and code */
//  color: #888; /* Line number text */
//}

/* Prism.js Theme Customization */
pre[class*="language-"],
code[class*="language-"] {
  white-space: pre-wrap !important; /* Allow lines to wrap */
  word-wrap: break-word !important; /* Break long words */
  overflow-wrap: break-word !important; /* Handle long content like URLs */
}

/* Responsive Adjustments */
@media screen and (max-width: 850px) {
  .email__code-container {
      width: 100%;
  }

  .email__code {
      width: 100%;
  }
}

/* General Reset */
*,
*::before,
*::after {
  box-sizing: border-box;
}

.email__code,
pre[class*="language-"],
code[class*="language-"] {
    white-space: pre-wrap; /* Retain formatting */
    line-height: 1.4; /* Reduce gaps between lines */
}

.token {
  display: inline !important; /* Prevent block-level rendering */
  margin: 0 !important; /* Remove unnecessary spacing */
  padding: 0 !important; /* Reset padding */
}
