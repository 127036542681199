:root {
    --loader-background-color: #EEEEEE;
    --loader-highlight-color: #DEDEDE;
}

#skeleton-loader {
    width: 85%;
    height: 20px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-bottom: 20px;
}

.skeleton-loader {
    width: 85%;
    height: 20px;
    background: linear-gradient(90deg, var(--loader-background-color) 25%, var(--loader-highlight-color) 50%, var(--loader-background-color) 75%);
    background-size: 200% 100%;
    animation: loading 2s infinite ease-in-out;
    border-radius: 8px;
    margin-bottom: 20px;
}

@keyframes loading {
    0% {
        background-position: 200% 0;
    }
    100% {
        background-position: -200% 0;
    }
}