/* Style the form */
/* Style the input fields */
/* Mark input boxes that gets an error on validation: */
/* Hide all steps by default: */
/* Make circles that indicate the steps of the form: */
/* Mark the active step: */
/* Mark the steps that are finished and valid: */
#regForm {
	background-color: #ffffff;
	margin: 100px auto;
	padding: 40px;
	width: 70%;
	min-width: 300px;
}
input {
	padding: 10px;
	width: 100%;
	font-size: 17px;
	font-family: Raleway;
	border: 1px solid #aaaaaa;
}
input.invalid {
	background-color: #ffdddd;
}
.tab {
	display: none;
}
.step {
	height: 15px;
	width: 15px;
	margin: 0 2px;
	background-color: #bbbbbb;
	border: none;
	border-radius: 50%;
	display: inline-block;
	opacity: 0.5;
}
.step.active {
	opacity: 1;
}
.step.finish {
	background-color: #4CAF50;
}
